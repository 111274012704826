<template>
  <NoteSection
    label="Wound Measurements"
  >
  <v-tabs
      v-model="activeTab"
      fixed-tabs
      color="success"
      class="col-sm-12 mb-5"
    >
      <v-tab :class="invalidWoundSize && 'required-field required-tab'">
        <strong v-if="invalidWoundSize">*</strong>
        Wound Size
      </v-tab>
      <v-tab :disabled="!isFollowUpTreatment">
        History
      </v-tab>
      <v-tab :disabled="!isFollowUpTreatment">
        Graph
      </v-tab>

      <!-- wound size -->
      <v-tab-item eager>
        <label class="section-title">Primary Dimensions (cm)</label>
        <checkbox
          :value="is_healed"
          label="Resolved"
          class="col-sm-2 align-self-center"
          hide-details
          @change="woundResolved"
        ></checkbox>
        <text-field
          v-model="wound_size.primary_length"
          class="col-sm-2p5"
          label="Length (cm)"
          numeric
          :mask="measurementMask"
          rules="numberRange(0,100)"
          label-top
          :required="primaryDimensionsRequired"
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.primary_length"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @input="checkIfResolved"
          @blur="wound_size.primary_length = wound_size.primary_length ? Number(wound_size.primary_length || 0).toFixed(1) : null"
        ></text-field>
        <text-field
          v-model="wound_size.primary_width"
          class="col-sm-2p5"
          label="Width (cm)"
          numeric
          :mask="measurementMask"
          rules="numberRange(0,100)"
          label-top
          :required="primaryDimensionsRequired"
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.primary_width"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @input="checkIfResolved"
          @blur="wound_size.primary_width = wound_size.primary_width ? Number(wound_size.primary_width || 0).toFixed(1) : null"
        ></text-field>
        <text-field
          v-model="wound_size.primary_depth"
          class="col-sm-2p5"
          label="Depth (cm)"
          numeric
          :mask="wound_size.primary_depth !== 'UTD' ? measurementMask : false"
          rules="numberRangeUTD(0,100)"
          label-top
          :append-icon="wound_size.primary_depth !== 'UTD' ? icons.mdiTimelineQuestion : null"
          :required="primaryDimensionsRequired"
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.primary_depth"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @input="checkIfResolved"
          @click:append="setWoundSizePrimaryDepth('UTD')"
          @click:clear="setWoundSizePrimaryDepth(null)"
          @blur="woundSizePrimaryDepth()"
        ></text-field>
        <text-field
          :value="wound_size.primary_area"
          class="col-sm-2p5"
          label="Area"
          hint="Auto calculated"
          :prefix="wound_size.primary_area < 10000 ? '=' : ''"
          suffix="cm²"
          label-top
          readonly
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.primary_area"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @focus="wound_size.primary_area && $event.target.blur()"
        ></text-field>

        <!-- Alerts: Prevent validation (set color="error" and add required checkbox) -->
        <v-expand-transition>
          <div
            v-if="invalidPrimaryDimensions"
            style="width: 100%"
          >
            <alert color="error">
              Zero values for wound length and width only apply to 100% epithelialized wounds.
              Length and width must both be zero.
              Otherwise, length and width must both be greater than zero.
              <checkbox required></checkbox>
            </alert>
          </div>
        </v-expand-transition>

        <label class="section-title">Pre-debridement Wound Bed (%)</label>
        <text-field
          v-model="wound_size.slough"
          class="col-sm-1p5"
          label="Slough"
          hint="Slough (%)"
          numeric
          mask="###"
          rules="numberRange(0,100)"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.slough"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.slough = wound_size.slough ? parseInt(wound_size.slough) : null"
        ></text-field>
        <text-field
          v-model="wound_size.granulation"
          class="col-sm-1p5"
          label="Granulation"
          hint="Granulation (%)"
          numeric
          mask="###"
          rules="numberRange(0,100)"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.granulation"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.granulation = wound_size.granulation ? parseInt(wound_size.granulation) : null"
        ></text-field>
        <text-field
          v-model="wound_size.necrotic_tissue"
          class="col-sm-1p5"
          label="Necrotic"
          hint="Necrotic (%)"
          numeric
          mask="###"
          rules="numberRange(0,100)"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.necrotic_tissue"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.necrotic_tissue = wound_size.necrotic_tissue ? parseInt(wound_size.necrotic_tissue) : null"
        ></text-field>
        <text-field
          v-model="wound_size.hyper_granulation"
          class="col-sm-1p5"
          label="Hyper granulation"
          hint="Hyper granulation (%)"
          numeric
          mask="###"
          rules="numberRange(0,100)"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.hyper_granulation"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.hyper_granulation = wound_size.hyper_granulation ? parseInt(wound_size.hyper_granulation) : null"
        ></text-field>
        <text-field
          v-model="wound_size.eschar"
          class="col-sm-1p5"
          label="Eschar"
          hint="Eschar (%)"
          numeric
          rules="numberRange(0,100)"
          mask="###"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.eschar"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.eschar = wound_size.eschar ? parseInt(wound_size.eschar) : null"
        ></text-field>
        <text-field
          v-model="wound_size.epithelial"
          class="col-sm-1p5"
          label="Epithelial"
          hint="Epithelial (%)"
          numeric
          rules="numberRange(0,100)"
          mask="###"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.epithelial"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.epithelial = wound_size.epithelial ? parseInt(wound_size.epithelial) : null"
        ></text-field>
        <text-field
          v-model="wound_size.dermal"
          class="col-sm-1p5"
          label="Dermal"
          hint="Dermal (%)"
          numeric
          mask="###"
          rules="numberRange(0,100)"
          suffix="%"
          not-clearable
          label-top
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.dermal"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.dermal = wound_size.dermal ? parseInt(wound_size.dermal) : null"
        ></text-field>
        <text-field
          ref="preDebridementTotal"
          :value="wound_size.pre_debridement_total"
          class="col-sm-1p5"
          label="Total (%)"
          hint="Auto totaled (%)"
          prefix="="
          readonly
          readonly-validate
          label-top
          rules="total100PercentRequired"
          :disabled="is_healed"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.pre_debridement_total"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @focus="Number(wound_size.pre_debridement_total || 0) === 100 && $event.target.blur()"
        ></text-field>

        <!-- Alerts: Prevent validation (set color="error" and add required checkbox) -->
        <v-expand-transition>
          <div
            v-if="invalidWoundBedTissueTypeTotal"
            style="width: 100%"
          >
            <alert color="error">
              Justify why a debridement was not performed when there is 30% or more slough/necrotic tissue.
              <checkbox required></checkbox>
            </alert>
          </div>
        </v-expand-transition>

        <checkbox
          v-model="wound_size.unhealthy_granulation"
          label="Unhealthy granulation tissue identified"
          :class="isEtiologyPressure ? 'col-sm-6' : 'col-sm-12'"
          :disabled="is_healed"
        ></checkbox>

        <select-box
          v-if="isEtiologyPressure && !is_healed"
          v-model="wound_size.wound_stage"
          :items="woundStages"
          class="col-sm-6"
          label="Wound Stage (NPUAP 4+2)"
          required
        >
        </select-box>

        <label class="section-title">Post-debridement Dimensions (cm)</label>
        <text-field
          v-model="wound_size.post_debridement_length"
          class="col-sm-1p75"
          label="Length (cm)"
          numeric
          :mask="measurementMask"
          rules="numberRange(0,100)"
          :required="postDebridementRequired"
          label-top
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_length"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.post_debridement_length = wound_size.post_debridement_length ? Number(wound_size.post_debridement_length || 0).toFixed(1) : null"
        ></text-field>
        <text-field
          v-model="wound_size.post_debridement_width"
          class="col-sm-1p75"
          label="Width (cm)"
          numeric
          :mask="measurementMask"
          rules="numberRange(0,100)"
          :required="postDebridementRequired"
          label-top
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_width"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @blur="wound_size.post_debridement_width = wound_size.post_debridement_width ? Number(wound_size.post_debridement_width || 0).toFixed(1) : null"
        ></text-field>
        <text-field
          v-model="wound_size.post_debridement_depth"
          class="col-sm-1p75"
          label="Depth (cm)"
          numeric
          :mask="wound_size.post_debridement_depth !== 'UTD' ? measurementMask : false"
          rules="numberRangeUTD(0,100)"
          :required="postDebridementRequired"
          label-top
          :append-icon="wound_size.post_debridement_depth !== 'UTD' ? icons.mdiTimelineQuestion : null"
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_depth"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @click:append="setWoundSizePostDepth('UTD')"
          @click:clear="setWoundSizePostDepth(null)"
          @blur="woundSizePostDepth()"
        ></text-field>
        <text-field
          :value="wound_size.post_debridement_area"
          class="col-sm-2p5"
          label="Area"
          hint="Auto calculated"
          :prefix="wound_size.post_debridement_area < 100 ? '=' : ''"
          suffix="cm²"
          label-top
          readonly
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_area"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @focus="wound_size.post_debridement_area && $event.target.blur()"
        ></text-field>
        <text-field
          v-model="wound_size.post_debridement_percent"
          class="col-sm-1p75"
          label="Debr. (%)"
          hint="Debridement (%)"
          numeric
          prefix="x"
          suffix="%"
          mask="###"
          rules="numberRange(1,100)"
          :required="postDebridementRequired"
          label-top
          not-clearable
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_percent"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
        ></text-field>
        <text-field
          ref="postAreaDebrided"
          :value="wound_size.post_debridement_area_debrided"
          class="col-sm-2p5"
          label="Area Debrided"
          hint="Auto calculated"
          :prefix="wound_size.post_debridement_area_debrided < 10 ? '=' : ''"
          suffix="cm²"
          label-top
          readonly
          readonly-validate
          rules="minimum(0.01)"
          :disabled="is_healed || !isProcedureDebridement"
          :previous-value="previousWoundTreatment.wound_treatment_wound_size.post_debridement_area_debrided"
          :previous-value-dos="latestTreatment && latestTreatment.encounter ? latestTreatment.encounter.visit_date : null"
          :input-history-custom-styles="'padding-top: 18px;'"
          @focus="Number(wound_size.post_debridement_area_debrided || 0) >= 0.01 && $event.target.blur()"
        ></text-field>
      </v-tab-item>

      <!-- history -->
      <v-tab-item
        v-if="isFollowUpTreatment"
        eager
      >
        <div style="width: 100%">
          <v-data-table
            :headers="headers"
            :items="wound_treatments"
            item-key="id"
            sort-by="visit_date"
            sort-desc
            hide-default-footer
            class="wound-measurement-history col-sm-12"
          >
            <!-- date -->
            <template #item.visit_date="{ item }">
              {{ $date(item.encounter.visit_date).format('M/D/YYYY') }}
            </template>
            <!-- length -->
            <template #item.length="{ item }">
              {{ formatMeasurement(item.wound_treatment.wound_treatment_wound_size.primary_length) }}
            </template>
            <!-- width -->
            <template #item.width="{ item }">
              {{ formatMeasurement(item.wound_treatment.wound_treatment_wound_size.primary_width) }}
            </template>
            <!-- depth -->
            <template #item.depth="{ item }">
              {{ formatMeasurement(item.wound_treatment.wound_treatment_wound_size.primary_depth) }}
            </template>
            <!-- area -->
            <template #item.area="{ item }">
              {{ formatMeasurement(item.wound_treatment.wound_treatment_wound_size.primary_area, true) }}
            </template>
            <!-- area debrided -->
            <template #item.area_debrided="{ item }">
              {{ item.wound_treatment.wound_treatment_wound_size.post_debridement_area_debrided ? item.wound_treatment.wound_treatment_wound_size.post_debridement_area_debrided : '0.00' }}
              cm²
            </template>
          </v-data-table>
        </div>
      </v-tab-item>

      <!-- graph -->
      <v-tab-item
        v-if="isFollowUpTreatment"
        eager
        class="full-size white-bg pl-1 pt-5"
      >
        <graph
          v-model="woundChartData"
          :labels="woundChartLabels"
          title="Wound Area Measurements"
          x-title="Measurement Dates"
          y-title="Area (cm²)"
          min="0"
          :max="maxValue"
          class="col-sm-12"
        ></graph>
      </v-tab-item>
    </v-tabs>
  </NoteSection>
</template>

<script>
import graph from '@/components/elements/LineGraph.vue'
import { mapGetters } from 'vuex'
import { mdiTimelineQuestion } from '@mdi/js'
import InputHistory from '@/components/elements/InputHistory.vue'

const woundResolved = {
  primary_length: '0.0',
  primary_width: '0.0',
  primary_depth: '0.0',
  slough: null,
  granulation: null,
  necrotic_tissue: null,
  hyper_granulation: null,
  eschar: null,
  epithelial: 100,
  dermal: null,
  pre_debridement_total: 100,
  unhealthy_granulation: false,
  wound_stage: null,
  post_debridement_length: null,
  post_debridement_width: null,
  post_debridement_depth: null,
  post_debridement_percent: null,
}

export default {
  components: { graph, InputHistory },
  props: {
    value: {
      type: Object,
      default: null,
    },
    treatments: {
      type: Array,
      default: null,
    },
    isHealed: {
      type: Boolean,
      default: null,
    },
    highlightForm: {
      type: Boolean,
      default: null,
    },
    isEtiologyPressure: {
      type: Boolean,
      default: null,
    },
    isProcedureDebridement: {
      type: Boolean,
      default: null,
    },
    procedureId: {
      type: [Number, String],
      default: null,
    },
    woundProgress: {
      type: String,
      default: null,
    },
    debridementNotPerformedReasons: {
      type: Array,
      default: null,
    },
    latestTreatment: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      activeTab: 0,
      wound_size: { ...this.value },
      wound_treatments: this.treatments.filter(x => x.deleted !== true).sort((a, b) => (a.encounter?.visit_date < b.encounter?.visit_date && 1) || -1),
      is_healed: this.isHealed,
      woundChartData: [
        { name: 'Wound Area', data: [] },
      ],
      woundChartLabels: [],
      wound_progress: this.woundProgress,
      maxValue: null,
      icons: { mdiTimelineQuestion },
      measurementMask: [/[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/, /[0-9|.]/],
      headers: [
        // Filters out encounters which are flagged to be deleted
        {
          value: 'deleted', align: 'hide', filter: value => value !== true,
        },
        {
          text: 'Date', value: 'visit_date', sortable: false,
        },
        {
          text: 'Length', value: 'length', sortable: false, align: 'right',
        },
        {
          text: 'Width', value: 'width', sortable: false, align: 'right',
        },
        {
          text: 'Depth', value: 'depth', sortable: false, align: 'right',
        },
        {
          text: 'Area', value: 'area', sortable: false, align: 'right',
        },
        {
          text: 'Area Debrided', value: 'area_debrided', sortable: false, align: 'right debrided',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('encounters', ['woundStages']),
    invalidWoundSize() {
      return this.primaryDimensionsInvalid
        || this.invalidPrimaryDimensions
        || this.preDebridementInvalid
        || this.postDebridementInvalid
        || this.woundStageInvalid
        || this.invalidWoundBedTissueTypeTotal
    },
    primaryArea() {
      if (this.$custom.isEmpty(this.wound_size.primary_length)
        || this.$custom.isEmpty(this.wound_size.primary_width)
      ) return null

      return (this.wound_size.primary_length * this.wound_size.primary_width).toFixed(2)
    },
    primaryDimensionsRequired() {
      return !!this.wound_size?.primary_length
        || !!this.wound_size?.primary_width
        || !!this.wound_size?.primary_depth
    },
    primaryDimensionsInvalid() {
      return this.primaryDimensionsRequired
        && (!this.primaryArea
          || !this.wound_size.primary_depth
          || this.wound_size.primary_depth > 100
        )
    },
    preDebridementTotal() {
      if (this.$custom.isEmpty(this.wound_size.slough)
        && this.$custom.isEmpty(this.wound_size.granulation)
        && this.$custom.isEmpty(this.wound_size.necrotic_tissue)
        && this.$custom.isEmpty(this.wound_size.hyper_granulation)
        && this.$custom.isEmpty(this.wound_size.eschar)
        && this.$custom.isEmpty(this.wound_size.epithelial)
        && this.$custom.isEmpty(this.wound_size.dermal)
      ) return null

      return Number(this.wound_size.slough || 0)
        + Number(this.wound_size.granulation || 0)
        + Number(this.wound_size.necrotic_tissue || 0)
        + Number(this.wound_size.hyper_granulation || 0)
        + Number(this.wound_size.eschar || 0)
        + Number(this.wound_size.epithelial || 0)
        + Number(this.wound_size.dermal || 0)
    },
    preDebridementInvalid() {
      // return this.preDebridementTotal !== 100 && !this.preDebridementTotal !== 0 && this.preDebridementTotal !== null
      return this.preDebridementTotal !== 100
    },
    postDebridementRequired() {
      return this.isProcedureDebridement
        || !!this.wound_size.post_debridement_length
        || !!this.wound_size.post_debridement_width
        || !!this.wound_size.post_debridement_depth
        || !!this.wound_size.post_debridement_percent
    },
    postDebridementArea() {
      if (!this.wound_size.post_debridement_length
        || !this.wound_size.post_debridement_width
      ) return null

      return (this.wound_size.post_debridement_length * this.wound_size.post_debridement_width).toFixed(2)
    },
    postDebridementAreaDebrided() {
      if (!this.wound_size.post_debridement_length
        || !this.wound_size.post_debridement_width
        || !this.wound_size.post_debridement_percent
      ) return null

      return (
        (this.wound_size.post_debridement_length
          * this.wound_size.post_debridement_width
          * this.wound_size.post_debridement_percent
        ) / 100).toFixed(2)
    },
    postDebridementInvalid() {
      return !this.is_healed
        && this.postDebridementRequired
        && (this.$custom.isEmpty(this.wound_size.post_debridement_depth)
          || !this.postDebridementAreaDebrided
          || this.wound_size.post_debridement_area_debrided < 0.01
        )
    },
    woundStageInvalid() {
      return this.isEtiologyPressure && !this.is_healed && !this.wound_size.wound_stage
    },
    isWoundResolved() {
      return !this.$custom.isEmpty(this.wound_size.primary_length)
        && !this.$custom.isEmpty(this.wound_size.primary_width)
        && !this.$custom.isEmpty(this.wound_size.primary_depth)
        && Number(this.wound_size.primary_length || 0) + Number(this.wound_size.primary_width || 0) + Number(this.wound_size.primary_depth || 0) === 0
    },
    isFollowUpTreatment() {
      return this.wound_treatments ? this.wound_treatments.length > 1 : false
    },
    isProcedureNone() {
      return Number(this.procedureId) === 999
    },
    invalidPrimaryDimensions() {
      return (
        !!this.wound_size.primary_length
        && !!this.wound_size.primary_width
        && ((
          Number(this.wound_size.primary_length || 0) === 0
          && Number(this.wound_size.primary_width || 0) > 0
        ) || (
          Number(this.wound_size.primary_width || 0) === 0
          && Number(this.wound_size.primary_length || 0) > 0
        ))
      )
    },
    invalidWoundBedTissueTypeTotal() {
      return !this.isProcedureDebridement
        && this.$custom.isEmpty(this.debridementNotPerformedReasons)
        && Number(this.wound_size.slough || 0) + Number(this.wound_size.necrotic_tissue || 0) >= 30
    },
    previousWoundSize() {
      return this.isFollowUpTreatment ? this.wound_treatments[1].wound_treatment.wound_treatment_wound_size.primary_area : false
    },
    previousWoundTreatment() {
      if (!this.latestTreatment?.wound_treatment) {
        // Return an empty object to avoid accessing properties on null or undefined
        return { wound_treatment_wound_size: {} }
      }
      return this.latestTreatment.wound_treatment
    },
    hasPreviousWoundTreatmentWoundSize() {
      return (
        this.previousWoundTreatment
        && this.previousWoundTreatment.wound_treatment_wound_size)
    },
  },
  watch: {
    /* No idea why this was added, doesn't appear to be required
    value: {
      deep: true,
      handler() {
        this.wound_size = this.value
        this.activeTab = 0
      },
    },
    */
    wound_size: {
      deep: true,
      handler() {
        this.calcTotals()
        this.drawGraph()
        this.$emit('input', this.wound_size)
      },
    },
    isHealed() {
      this.is_healed = this.isHealed
    },
    is_healed() {
      this.$emit('update:is-healed', this.is_healed)
    },
    highlightForm() {
      // Select the first tab with invalid fields
      if (this.highlightForm && this.invalidWoundSize) {
        this.activeTab = 0
      }
    },
    procedureId() {
      if (!this.isProcedureDebridement) {
        this.$nextTick(() => {
          this.wound_size.post_debridement_length = null
          this.wound_size.post_debridement_width = null
          this.wound_size.post_debridement_depth = null
          this.wound_size.post_debridement_percent = null
        })
      }
    },
    woundProgress() {
      this.wound_progress = this.woundProgress
    },
    'wound_size.primary_area': {
      // When wound primary dimensions are changed, look to set the Wound Progress field
      handler() {
        this.calcWoundProgress()
      },
    },
  },
  mounted() {
    this.calcTotals()
    this.drawGraph()
    this.calcWoundProgress()

    // This hack clicks and unclicks input fields, which allows rule to indicate if the field value is incorrect
    const totalInput = this.$refs.preDebridementTotal?.$el.querySelector('input')
    const areaDebrided = this.$refs.postAreaDebrided?.$el.querySelector('input')
    if (totalInput && areaDebrided) {
      this.$nextTick(() => {
        totalInput.focus()
        this.$nextTick(() => {
          areaDebrided.focus()
          this.$nextTick(() => {
            areaDebrided.blur()
            totalInput.blur()

            // Change focus to the wound location.
            this.$emit('focusWoundLoc')
          })
        })
      })
    }
  },
  methods: {
    calcWoundProgress() {
      if (this.wound_progress !== 'None' && this.wound_progress !== 'Other') {
        if (!this.isFollowUpTreatment) {
          this.wound_progress = 'Undetermined: First visit'
          this.$emit('update:wound-progress', this.wound_progress)
        } else if (this.wound_size.primary_area === '0.00') {
          this.wound_progress = 'Wound is epithelialized'
          this.$emit('update:wound-progress', this.wound_progress)
        } else if (this.wound_size.primary_area && this.previousWoundSize) {
          if (this.wound_size.primary_area > this.previousWoundSize) {
            this.wound_progress = 'Wound has increased in size'
          } else if (this.wound_size.primary_area < this.previousWoundSize) {
            this.wound_progress = 'Wound has decreased in size'
          } else {
            this.wound_progress = 'Wound is unchanged in size'
          }
          this.$emit('update:wound-progress', this.wound_progress)
        }
      }
    },
    formatMeasurement(measurement, area = false) {
      if (measurement === 'UTD') return 'UTD'

      let formatted = ''
      if (!this.$custom.isEmpty(measurement)) {
        formatted = Number(measurement).toFixed(area ? 2 : 1)
      } else if (area) {
        formatted = '0.0'
      }
      if (formatted) {
        formatted += area ? ' cm²' : ' cm'
      }

      return formatted
    },
    checkIfResolved() {
      if (this.isWoundResolved) {
        this.is_healed = true
        this.wound_size = {
          ...this.wound_size,
          ...woundResolved,
        }
      }
    },
    woundResolved(newWoundStatus) {
      this.is_healed = newWoundStatus
      if (newWoundStatus) {
        if (!this.isWoundResolved) {
          this.$root.confirm({
            title: 'Are you sure wound is resolved?',
            body: 'Changing wound to resolved will set primary dimensions to zero, epithelial tissue to 100%, and clear post-debridement dimensions.',
          }).then(result => {
            if (result) {
              this.wound_size.primary_depth = null
              this.$nextTick(() => {
                this.wound_size = {
                  ...this.wound_size,
                  ...woundResolved,
                }
              })
            } else {
              this.is_healed = false
            }
          })
        }
      } else {
        this.$nextTick(() => {
          this.is_healed = false
          this.wound_size.primary_length = null
          this.wound_size.primary_width = null
          this.wound_size.primary_depth = null
          this.wound_size.post_debridement_length = null
          this.wound_size.post_debridement_width = null
          this.wound_size.post_debridement_depth = null
          this.wound_size.post_debridement_percent = null
          this.wound_size.epithelial = null
          this.wound_size.pre_debridement_total = null
        })
      }
    },
    setWoundSizePrimaryDepth(value) {
      this.wound_size = {
        ...this.wound_size,
        primary_depth: value,
      }
    },
    setWoundSizePostDepth(value) {
      this.wound_size = {
        ...this.wound_size,
        post_debridement_depth: value,
      }
    },
    woundSizePrimaryDepth() {
      this.$nextTick(() => {
        if (this.wound_size.primary_depth !== 'UTD') {
          this.wound_size.primary_depth = this.wound_size.primary_depth
            ? Number(this.wound_size.primary_depth || 0).toFixed(1)
            : null
        }
      })
    },
    woundSizePostDepth() {
      this.$nextTick(() => {
        if (this.wound_size.post_debridement_depth !== 'UTD') {
          this.wound_size.post_debridement_depth = this.wound_size.post_debridement_depth
            ? Number(this.wound_size.post_debridement_depth || 0).toFixed(1)
            : null
        }
      })
    },
    calcTotals() {
      this.wound_size.primary_area = this.primaryArea
      this.wound_size.post_debridement_area = this.postDebridementArea
      this.wound_size.post_debridement_area_debrided = this.postDebridementAreaDebrided
      if (this.is_healed) {
        this.wound_size.wound_size_text = 'Wound resolved'
      } else if (this.notEmpty(this.wound_size.primary_length) && this.notEmpty(this.wound_size.primary_width) && this.notEmpty(this.wound_size.primary_depth) && this.wound_size.primary_depth === 'UTD') {
        this.wound_size.wound_size_text = `${this.wound_size.primary_length} cm x ${this.wound_size.primary_width} cm x UTD`
      } else if (this.notEmpty(this.wound_size.primary_length) && this.notEmpty(this.wound_size.primary_width) && this.notEmpty(this.wound_size.primary_depth)) {
        this.wound_size.wound_size_text = `${this.wound_size.primary_length} cm x ${this.wound_size.primary_width} cm x ${this.wound_size.primary_depth} cm`
      } else if (this.notEmpty(this.wound_size.primary_length) && this.notEmpty(this.wound_size.primary_width)) {
        this.wound_size.wound_size_text = `${this.wound_size.primary_length} cm x ${this.wound_size.primary_width} cm`
      } else {
        this.wound_size.wound_size_text = null
      }

      this.wound_size.pre_debridement_total = this.preDebridementTotal
    },
    drawGraph() {
      // Fill arrays for wound size chart
      this.maxValue = 0
      const filteredTreatments = this.wound_treatments.filter(x => !x.deleted)
      filteredTreatments.forEach((item, index) => {
        const newIndex = filteredTreatments.length - index - 1
        if (item.wound_treatment && item.wound_treatment.wound_treatment_wound_size.primary_area) {
          this.woundChartData[0].data[newIndex] = Number(item.wound_treatment.wound_treatment_wound_size.primary_area || 0)
        } else {
          this.woundChartData[0].data[newIndex] = null
        }
        this.woundChartLabels[newIndex] = this.$date(item.encounter?.visit_date).format('M/D/YY')
        this.maxValue = Math.max(this.maxValue, item.wound_treatment ? item.wound_treatment.wound_treatment_wound_size.primary_area : 0)
      })

      if (this.maxValue > 5) {
        this.maxValue = Math.ceil(this.maxValue / 5) * 5
      } else {
        this.maxValue = Math.ceil(this.maxValue / 2) * 2
      }
    },
    notEmpty(value) {
      return value !== null && value !== ''
    },
  },
}
</script>

<style lang="scss">
.v-data-table.wound-measurement-history {
  padding: 0;
  .v-data-table__wrapper {
    margin-bottom: 12px;
    max-height: 302px;
    overflow-y: auto;
    border: thin solid rgba(0, 0, 0, 0.14);
    border-radius: 0;
    table {
      thead {
        th {
          text-align: center !important;
          width: 16.666%;
          &.debrided {
            span {
              display: flex;
              white-space: break-spaces;
              margin: 0 auto;
            }
          }
          span {
            font-size: 15px !important;
          }
        }
      }
      th, td {
        height: 50px !important;
        font-size: 15px !important;
        border-right: thin solid rgba(0, 0, 0, 0.14);
        &:first-child {
          border-left: thin solid rgba(0, 0, 0, 0.14);
        }
      }
      tbody {
        tr td {
          color: var(--v-secondary-darken3) !important;
          padding: 0 15px !important;
          white-space: nowrap;
          &:first-child {
            color: var(--v-secondary-darken1) !important;
            text-align: center !important;
          }
        }
        tr:last-child td {
          border-bottom: thin solid rgba(0, 0, 0, 0.14);
        }
      }
    }
  }
}
</style>
