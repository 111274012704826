<template>
  <NoteSection
    label="Reason for Visit"
  >
    <text-area
      v-model="visit_reason"
      class="col-sm-9.5"
      label="Enter reason for visit..."
      required
      ai
      counter="7300"
      maxlength="7300"
      word-count="6"
      @click:prepend="generateReasonForVisit"
      @input="$emit('input', visit_reason)"
    ></text-area>
    <!-- <checkbox
      v-model="add_to_note"
      label="Add to note"
      class="col-sm-2.5 align-self-center"
      @input="$emit('update:add-to-note', add_to_note)"
    ></checkbox> -->
  </NoteSection>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: null,
    },

    // addToNote: {
    //   type: Boolean,
    //   default: false,
    // },
    woundList: {
      type: Array,
      default: null,
    },
    treatmentStatus: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visit_reason: this.value,

      // add_to_note: this.addToNote,
    }
  },
  computed: {},
  watch: {},
  methods: {
    generateReasonForVisit() {
      if (!this.treatmentStatus) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Treatments not validated!',
          body: 'You must have at least one treatment for this encounter and all encounter treatments must be validated before generating automatic text.',
          cancel: false,
        })

        return
      }
      let visitReason = ''
      const introText = [
        'I was asked to see this patient for my opinion on how to manage their',
        'To manage the patient\'s',
        'Management of',
        'Consultation and evaluation of',
        'Consultation for',
        'Evaluation of',
        'To evaluate',
        'To evaluate this patient for',
        'I am here to manage',
      ]
      const woundItems = []
      const dermItems = []
      const gTubeItems = []
      if (!this.$custom.isEmpty(this.woundList)) {
        this.woundList.forEach(wound => {
          if (wound.practice_type_id === 1) {
            woundItems.push(wound)
          } else if (wound.practice_type_id === 5) {
            gTubeItems.push(wound)
          } else if (wound.practice_type_id === 6) {
            dermItems.push(wound)
          }
        })
      }
      const woundLocations = []
      const dermLocations = []
      woundItems.forEach(wound => {
        if (wound.location !== null && wound.location?.location_text !== null) {
          woundLocations.push(wound.location?.location_text)
        }
      })
      dermItems.forEach(derm => {
        if (derm.location !== null && derm.location?.location_text !== null) {
          dermLocations.push(derm.location?.location_text)
        }
      })
      if (woundItems.length > 0 || dermItems.length > 0 || gTubeItems.length > 0) {
        visitReason += this.$custom.randomString(introText)
        if (woundItems.length > 0) {
          const woundLocationsText = this.$custom.toListCommaAnd(woundLocations, null, 'none')
          visitReason += woundItems.length > 1 ? 'wounds' : 'a wound'
          visitReason += this.$custom.randomString(['located', 'found'])
          visitReason += this.$custom.randomString(['at the', 'on the'])
          visitReason += this.$custom.unCapitalizeFirstLetter(woundLocationsText)
        }
        if (woundItems.length > 0 && (dermItems.length > 0 || gTubeItems.length > 0)) {
          visitReason += ' and '
        }
        if (dermItems.length > 0) {
          if (dermItems.length === 1) {
            visitReason += ' a '
          }
          const dermLocationsText = this.$custom.toListCommaAnd(dermLocations, null, 'none')
          visitReason += dermItems.length > 1 ? 'skin lesions' : 'skin lesion'
          visitReason += this.$custom.randomString(['located', 'found'])
          visitReason += this.$custom.randomString(['at', 'on'])
          visitReason += ' the '
          visitReason += this.$custom.unCapitalizeFirstLetter(dermLocationsText)
        }
        if (gTubeItems.length > 0) {
          if (woundItems.length > 0 || dermItems.length > 0) {
            visitReason += ', and '
          }
          gTubeItems.forEach(gTubeItem => {
            if (gTubeItem.treatment.procedure_id === 150) {
              visitReason += 'G-tube-related issues'
            } else if ([300, 301].includes(gTubeItem.treatment.procedure_id)) {
              visitReason += 'emergent replacement of a gastrostomy tube'
            } else if ([311, 312, 321, 322, 331, 332].includes(gTubeItem.treatment.procedure_id)) {
              visitReason += 'replacement of a gastrostomy tube'
            } else {
              visitReason += 'G-tube-related issues'
            }
          })
        }
        visitReason += '.  '
      }
      this.visit_reason = this.$custom.clean(visitReason, 'Visit Reason')
      this.$emit('input', this.visit_reason)
    },
  },
}
</script>
