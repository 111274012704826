<template>
  <NoteSection class="wound-treatments">
    <v-card-actions class="col-sm-12">
      <v-spacer class="col-sm-4"></v-spacer>
      <!-- wound -->
      <btn
        label="Wound"
        class="col-sm-2"
        :icon="icons.mdiTableRowPlusAfter"
        :disabled="isSigned"
        @click="addWound(1)"
      ></btn>

      <v-spacer></v-spacer>

      <!-- derm -->
      <btn
        label="Derm"
        class="col-sm-2"
        :icon="icons.mdiTableRowPlusAfter"
        :disabled="isSigned"
        @click="addWound(6)"
      ></btn>

      <v-spacer></v-spacer>

      <!-- g-tube -->
      <btn
        label="G-Tube"
        class="col-sm-2"
        :icon="icons.mdiTableRowPlusAfter"
        :disabled="isSigned"
        @click="addWound(5)"
      ></btn>
    </v-card-actions>

    <!-- Show error and prevent validation -->
    <alert
      v-if="!hasTreatment && !isSigned"
      color="error"
    >
      There must be at least one treatment record to pass validation.
      <checkbox required></checkbox>
    </alert>

    <div
      v-for="(item, index) in wounds"
      :key="`item-${index}`"
      :ref="`wound-${item.id}`"
      class="col-sm-12"
    >
      <div
        v-if="woundExistsNow(item)"
      >
        <!--
        The latestTreatment() method can be CPU intense, and was being accessed many times
        in the section below. So this "loop" is a trick to only run the latestTreatment()
        once per wound. There's only one item in the array, so it only renders once, but
        it allows the use of the "treatment" value to be used without additional overhead.
       -->
        <div
          v-for="(treatment) in [latestTreatment(item)]"
          :key="treatment.id"
          class="wound"
          :class="treatmentIsValidated(treatment) === false ? 'not-validated' : ''"
        >
          <v-card-actions>
            <btn
              label="Delete"
              color="error"
              class="col-sm-2"
              :icon="icons.mdiTrashCan"
              :disabled="isSigned || item.can_be_deleted !== true || (!isNewTreatment(treatment) && item.treatments && item.treatments.length > 1)"
              @click="deleteWoundRequest(index)"
            ></btn>

            <v-spacer></v-spacer>

            <!-- current treatment - validated -->
            <v-icon
              v-if="isNewTreatment(treatment) && treatmentIsValidated(treatment)"
              large
              color="success"
              class="mr-3"
            >
              {{ icons.mdiCheckDecagram }}
            </v-icon>

            <!-- current treatment - NOT validated -->
            <v-icon
              v-if="isNewTreatment(treatment) && !treatmentIsValidated(treatment)"
              large
              color="error"
              class="mr-3"
            >
              {{ icons.mdiAlertDecagram }}
            </v-icon>

            <!-- previous treatment -->
            <v-icon
              v-if="!isNewTreatment(treatment)"
              large
              color="warning"
              class="mr-3"
            >
              {{ icons.mdiClipboardAlert }}
            </v-icon>

            <div
              class="practice-type-title"
            >
              #{{ item.wound_number }} -
              {{ $store.getters['baseData/practiceTypeFromId'](item.practice_type_id).title }}
            </div>

            <v-icon
              v-if="item.practice_type_id === 1 && treatment.wound_treatment && treatment.wound_treatment.infection_signs && treatment.wound_treatment.infection_signs.some(x => x.title !== 'None')"
              large
              color="error"
              class="ml-3"
            >
              {{ icons.mdiBiohazard }}
            </v-icon>

            <v-spacer></v-spacer>

            <btn
              v-if="isNewTreatment(treatment)"
              :label="isSigned ? 'View' : 'Edit'"
              :color="isSigned ? 'primary' : (treatmentIsValidated(treatment) ? 'success' : 'error')"
              class="col-sm-2"
              :icon="isSigned ? icons.mdiClipboardText : icons.mdiClipboardEdit"
              @click="editTreatment(item)"
            ></btn>

            <btn
              v-else
              label="Add"
              class="col-sm-2"
              color="warning"
              :icon="icons.mdiClipboardPlus"
              :disabled="isSigned"
              @click="addTreatment(item)"
            ></btn>
          </v-card-actions>

          <div class="wound-treatment-details">
            <!-- This tells Vue the wound treatment isn't validated, so the form isn't validated -->
            <checkbox
              v-if="treatmentIsValidated(treatment) === false"
              required
              style="display: none"
            ></checkbox>

            <v-row>
              <label class="col-sm-3 text-right">
                Status:
              </label>
              <div :class="`col-sm-9 ${isNewTreatment(treatment) ? '' : 'warning--text'}`">
                {{ formattedStatus(treatment) }}
              </div>
            </v-row>

            <v-row v-if="item.practice_type_id !== 5">
              <label class="col-sm-3 text-right">
                Location:
              </label>
              <div class="col-sm-9">
                {{ formattedLocation(item) }}
              </div>
            </v-row>

            <v-row v-if="item.practice_type_id !== 5">
              <label class="col-sm-3 text-right">
                Etiology:
              </label>
              <div class="col-sm-9">
                {{ formattedEtiology(treatment) }}
              </div>
            </v-row>

            <v-row v-if="item.practice_type_id !== 6">
              <label class="col-sm-3 text-right">
                Size (LxWxD):
              </label>
              <div :class="`col-sm-9 ${isResolved(item) ? 'success--text' : ''}`">
                {{ formattedSize(item, treatment) }}
              </div>
            </v-row>

            <v-row v-if="item.practice_type_id === 1">
              <label class="col-sm-3 text-right">
                Progress:
              </label>
              <div class="col-sm-9">
                {{ formattedProgress(item, treatment) }}
              </div>
            </v-row>

            <v-row>
              <label class="col-sm-3 text-right">
                Treatment Summary:
              </label>
              <div class="col-sm-9">
                {{ formattedSummary(treatment) }}
              </div>
            </v-row>
          </div>
        </div>
      </div>
    </div>

    <!-- wound detail entry modal -->
    <WoundDetail
      v-model="wound"
      :modal-state.sync="woundModalState"
      :patient="patient"
      :is-signed="isSigned"
      :is-circulation-compromised="isCirculationCompromised"
      :encounter-attachments="encounterAttachments"
      :encounter-id="encounterId"
      :visit-location="visitLocation"
      :quick-entry="quickEntry"
      :latest-treatment="calculatedLatestTreatment"
    ></WoundDetail>

    <!-- derm detail entry modal -->
    <DermDetail
      v-model="wound"
      :modal-state.sync="dermModalState"
      :patient="patient"
      :is-signed="isSigned"
      :encounter-attachments="encounterAttachments"
      :encounter-id="encounterId"
      :therapeutic-anticoagulant="therapeuticAnticoagulant"
      :visit-location="visitLocation"
      :quick-entry="quickEntry"
      :latest-treatment="calculatedLatestTreatment"
    ></DermDetail>

    <!-- g-tube management modal -->
    <GTubeDetail
      v-model="wound"
      :modal-state.sync="gTubeModalState"
      :patient="patient"
      :is-signed="isSigned"
      :encounter-attachments="encounterAttachments"
      :encounter-id="encounterId"
      :visit-location="visitLocation"
      :quick-entry="quickEntry"
    ></GTubeDetail>
  </NoteSection>
</template>

<script>
import {
  mdiTableRowPlusAfter, mdiTrashCan, mdiClipboardEdit, mdiCheckDecagram,
  mdiAlertDecagram, mdiClipboardText, mdiClipboardPlus, mdiClipboardAlert,
  mdiAlert, mdiBiohazard,
} from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters } from 'vuex'
import WoundDetail from '@/components/notes/wound-detail/treatments/WoundDetail.vue'
import DermDetail from '@/components/notes/wound-detail/treatments/DermDetail.vue'
import GTubeDetail from '@/components/notes/wound-detail/treatments/GTubeDetail.vue'

export default {
  components: { WoundDetail, DermDetail, GTubeDetail },
  props: {
    value: {
      type: Array,
      default: null,
    },
    practiceTypeId: {
      type: Number,
      default: null,
    },
    patient: {
      type: Object,
      default: null,
    },
    isSigned: {
      type: [Boolean, Number],
      default: null,
    },
    woundTreatmentUpdated: {
      type: Boolean,
      default: null,
    },
    encounterAttachments: {
      type: Array,
      default: null,
    },
    encounterId: {
      type: String,
      default: null,
    },
    visitDate: {
      type: String,
      default: null,
    },
    placeOfServiceId: {
      type: Number,
      default: null,
    },
    isCirculationCompromised: {
      type: Boolean,
      default: null,
    },
    highlightForm: {
      type: Boolean,
      default: null,
    },
    therapeuticAnticoagulant: {
      type: Boolean,
      default: null,
    },
    visitLocation: {
      type: String,
      default: null,
    },
    quickEntry: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      wounds: [...this.value],
      woundModalState: false,
      dermModalState: false,
      gTubeModalState: false,
      wound: null,
      hasTreatment: false,
      calculatedLatestTreatment: null,
      practiceType: this.$store.getters['baseData/practiceTypeFromId'](this.practiceTypeId),
      icons: {
        mdiTableRowPlusAfter,
        mdiTrashCan,
        mdiClipboardEdit,
        mdiCheckDecagram,
        mdiAlertDecagram,
        mdiClipboardText,
        mdiClipboardPlus,
        mdiClipboardAlert,
        mdiAlert,
        mdiBiohazard,
      },
      timeoutId: null,
    }
  },
  beforeDestroy() {
    // Cleanup the timeout to avoid memory leaks
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  },
  computed: {
    ...mapGetters('encounters', [
      'woundEtiologies',
      'dermEtiologies',
    ]),
    isQuickEntry() {
      return (this.$route.query.quick || this.$route.query.quick === 'true') && this.quickEntry
    },
  },
  watch: {
    woundModalState() {

      if (!this.woundModalState) {
        if (this.wound.treatments && this.wound.treatments.length === 0 && this.wound.can_be_deleted) {
          this.deleteWound()
        } else if (this.wound) {
          this.updateWoundTreatment()
        }
      }
    },
    dermModalState() {
      if (!this.dermModalState) {
        if (this.wound.treatments && this.wound.treatments.length === 0 && this.wound.can_be_deleted) {
          this.deleteWound()
        } else if (this.wound) {
          this.updateWoundTreatment()
        }
      }
    },
    gTubeModalState() {
      if (!this.gTubeModalState) {
        if (this.wound.treatments && this.wound.treatments.length === 0 && this.wound.can_be_deleted) {
          this.deleteWound()
        } else if (this.wound) {
          this.updateWoundTreatment()
        }
      }
    },
  },
  mounted() {
    this.hasTreatmentUpdate()
  },
  methods: {
    setLatestTreatmentFromPracticeType(practiceTypeId) {
      let latestTreatments = this.$store.getters['patients/getLatestTreatments'](this.patient.id, this.visitDate, practiceTypeId, this.wound.id)

      if (!latestTreatments) {
        this.calculatedLatestTreatment = null

        return
      }

      // Filter only signed encounters only if the property is_signed doesn't exist on the treatment.encounter object
      if (latestTreatments && latestTreatments.some(trt => !(trt.hasOwnProperty('is_signed')))) {
        latestTreatments = latestTreatments.filter(latestTreatment => this.$store.getters['encounters/getById'](latestTreatment.encounter_id).is_signed)
      }

      this.calculatedLatestTreatment = latestTreatments[0]
    },
    treatmentIsValidated(treatment) {
      // Only evaluate if there's a treatment and the treatment's encID matches the encounter's ID.
      return treatment && treatment.encounter_id === this.encounterId
        ? (treatment.is_validated) || (this.isQuickEntry && treatment.is_quick_note_validated)
        : true
    },
    woundExistsNow(wound) {
      /*
        If there's no previous or current treatments, but there's
        treatments in the future, this wound didn't exist at this time
      */
      return (this.latestTreatment(wound)
        || wound.treatments.findIndex(x => !x.deleted && x.encounter.visit_date > this.visitDate) === -1
      )
    },
    latestTreatment(wound) {
      if (!wound.treatments.length) return false

      const treatment = wound.treatments.find(x => x.encounter_id === this.encounterId && !x.deleted)
      if (treatment) return treatment

      const treatments = wound.treatments.filter(x => !x.deleted && x.encounter.visit_date < this.visitDate).sort((a, b) => (a.encounter.visit_date < b.encounter.visit_date && 1) || -1)

      return (Array.isArray(treatments) && treatments.length) ? treatments[0] : false
    },
    isNewTreatment(treatment) {
      return (!treatment || !treatment.encounter) ? false : this.encounterId === treatment.encounter_id
    },
    formattedStatus(treatment) {
      if (!treatment || !treatment.encounter) return null

      if (this.encounterId === treatment.encounter_id) {
        return `Current encounter treatment ${this.$date(treatment.encounter.visit_date).format('M/D/YYYY')}`
      }

      return `Previous treatment date ${this.$date(treatment.encounter.visit_date).format('M/D/YYYY')}`
    },
    formattedLocation(wound) {
      return wound.location ? wound.location.location_text : null
    },
    formattedEtiology(treatment) {
      if (!treatment) return null

      const { etiology } = treatment

      return etiology === 'Other' ? `Other - ${treatment.etiology_other}` : etiology
    },
    formattedSize(wound, treatment) {
      if (!treatment) return null

      if (Number(wound.practice_type_id) === 1 && treatment.wound_treatment) {
        return treatment.wound_treatment.wound_treatment_wound_size.wound_size_text
      }
      if (Number(wound.practice_type_id) === 5 && treatment.g_tube_treatment) {
        return treatment.g_tube_treatment.replacement_size
      }

      return null
    },
    formattedProgress(wound, treatment) {
      if (!treatment || !treatment.wound_treatment) return null

      const { progress } = treatment.wound_treatment

      return progress === 'Other' ? `Other - ${treatment.wound_treatment.progress_other}` : progress
    },
    formattedSummary(treatment) {
      if (!treatment || !treatment.encounter) return null

      return this.$store.getters['baseData/procedureFromId'](treatment.procedure_id)
    },
    isResolved(wound) {
      if (!wound) return false

      if (Number(wound.practice_type_id) === 1) {
        return wound.is_healed
      }

      return false
    },
    deleteWound() {
      const index = this.wounds.findIndex(x => x.id === this.wound.id)
      if (index !== -1 && this.wound.delete_wound && this.wound.can_be_deleted) {
        this.wounds.splice(index, 1)
        this.wound = null
        this.$emit('input', this.wounds)
        this.$emit('update:wound-treatment-updated', true)
      }
    },
    hasTreatmentUpdate() {
      // Update the hasTreatment value which indicates if there's any treatments for this encounter
      this.hasTreatment = false
      this.wounds.forEach(wound => {
        if (wound.treatments ? wound.treatments.findIndex(x => x.encounter_id === this.encounterId && !x.deleted) !== -1 : false) {
          this.hasTreatment = true
        }
      })
    },
    updateWoundTreatment() {
      // Find the index of the wound if it already exists or return -1
      const index = this.wounds.length > 0
        ? this.wounds.findIndex(x => x.id === this.wound.id)
        : -1

      // If the wound doesn't exist, we created a new wound by adding it to then end of the array
      if (index === -1) this.wounds.push(this.wound)
      else this.wounds[index] = this.wound

      this.$emit('input', this.wounds)
      this.$emit('update:wound-treatment-updated', true)
      this.scrollToWound(this.wound.id)
      this.wound = null

      this.hasTreatmentUpdate()
    },
    openDialog(practiceType) {
      this.setLatestTreatmentFromPracticeType(practiceType)
      switch (practiceType) {
        case 1:
          this.woundModalState = true
          break
        case 6:
          this.dermModalState = true
          break
        case 5:
          this.gTubeModalState = true
          break
        default:
      }
    },
    addWound(practiceType) {
      // Wound treatment form is quite large and there can be a rendering delay, so show the loading modal
      // if (practiceType === 1) this.$store.dispatch('loading', true)
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        // The wound number is for the UI only as a reference for multiple wounds
        const woundNumber = this.wounds.length > 0
          ? Math.max(...this.wounds.map(o => o.wound_number)) + 1
          : 1

        // Build the start of a new wound
        const woundId = uuidv4()
        const now = this.$custom.utcNow()
        this.wound = {
          // Changes here should also be duplicated in QuickEntry.vue, method `addWound`
          id: woundId,
          patient_id: this.patient.id,
          encounter_id: this.encounterId,
          provider_user_id: this.$authUser.id(),
          practice_type_id: practiceType,
          wound_number: woundNumber,
          is_healed: false,
          created: now,
          is_wound_facility_acquired: false,
          is_wound_not_recent: false,
          wound_acquired_facility_id: null,

          // Not in the DB, for Vue only
          place_of_service_id: this.placeOfServiceId, // I don't believe this is being used, it's not in the DB
          can_be_deleted: true,
          followup_treatment: false,
          updated: true,

          treatments: [{
            id: uuidv4(),
            place_of_service_id: this.placeOfServiceId,
            patient_id: this.patient.id,
            encounter_id: this.encounterId,
            wound_id: woundId,
            etiology: null,
            etiology_other: null,
            procedure_id: null,
            current_treatment: null,
            qent_notes: null,
            operative_note: null,
            is_validated: false,
            is_quick_note_validated: practiceType !== 1 && practiceType !== 6,
            created: now,
            encounter: {
              id: this.encounterId,
              visit_date: this.visitDate,
            },

            // Not in the DB, for Vue only
            require_vitals: null,
            can_be_deleted: true,
          }],
        }

        // Add the wound_id to the wound location for wound and derm practice types
        if (practiceType === 1 || practiceType === 6) {
          this.wound.location = { wound_id: woundId }
        }
        this.openDialog(practiceType)
      }, 1)
    },
    async addTreatment(wound) {
      // Check if the wound is healed
      if (wound.is_healed) {
        // Show confirmation dialog
        const result = await this.$root.confirm({
          title: 'Confirm Treatment Addition',
          body: 'This wound is healed. Are you sure you want to add a treatment?',
          confirm: 'Add a treatment',
          cancel: 'Cancel',
        })
        if (!result) {
          return // Exit if the user did not confirm adding treatment to a healed wound.
        }
      }
      // Wound treatment form is quite large and there can be a rendering delay, so show the loading modal
      // if (wound.practice_type_id === 1) this.$store.dispatch('loading', true)
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        const latestTreatment = this.latestTreatment(wound)

        // Changes here should also be duplicated in QuickEntry.vue, method `addTreatment`

        // Find a etiology match...
        let etiology = null
        let etiologyOther = null
        if (latestTreatment) {
          etiology = latestTreatment.etiology
          etiologyOther = latestTreatment.etiology_other
          if (latestTreatment.etiology && (wound.practice_type_id === 1 || wound.practice_type_id === 6)) {
            const etiologies = wound.practice_type_id === 1 ? this.woundEtiologies : this.dermEtiologies
            if (etiologies.findIndex(x => x.title === etiology) === -1) {
              const etiologyIndex = etiologies.findIndex(x => x.title === this.$custom.toProperCaseAll(latestTreatment.etiology))
              if (etiologyIndex > -1) {
                etiology = etiologies[etiologyIndex].title
              } else if (etiology && !etiologyOther) {
                etiologyOther = etiology
                etiology = 'Other'
              }
            }
          }
        }

        const newTreatmentId = uuidv4()
        const newTreatment = {
          id: newTreatmentId,
          place_of_service_id: this.placeOfServiceId,
          patient_id: this.patient.id,
          encounter_id: this.encounterId,
          wound_id: wound.id,
          etiology,
          etiology_other: etiologyOther,
          procedure_id: null,
          current_treatment: null,
          qent_notes: null,
          operative_note: null,
          is_validated: false,
          is_quick_note_validated: true,
          created: this.$custom.utcNow(),
          encounter: {
            id: this.encounterID,
            visit_date: this.visitDate,
          },

          // Not in the DB, for Vue only
          require_vitals: null,
          can_be_deleted: true,
          updated: true,
        }

        if (wound.practice_type_id === 1) {
          // Wound treatment
          newTreatment.wound_treatment = {
            wound_treatment_wound_size: {},
          }

          // Pull in consent data from operative note wizard, mainly the consent info
          if (latestTreatment && latestTreatment.operative_note_wizard) {
            newTreatment.operative_note_wizard = {
              consent_selected: latestTreatment.operative_note_wizard.consent_selected,
              consent_text: latestTreatment.operative_note_wizard.consent_text,
            }
          }

          // Wound stage from pervious treatment carries forward
          if (latestTreatment) {
            const woundStage = latestTreatment?.wound_treatment?.wound_treatment_wound_size?.wound_stage
            if (woundStage) {
              newTreatment.wound_treatment.wound_treatment_wound_size.wound_stage = woundStage
            }
          }
        } else if (wound.practice_type_id === 6) {
          // Derm treatment
          newTreatment.derm_treatment = {}
        } else if (wound.practice_type_id === 5) {
          // G-Tube treatment
          newTreatment.g_tube_treatment = {}
        }

        wound.treatments.push(newTreatment)
        this.wound = {
          ...wound,

          // Not in the DB, for Vue only
          // If there are treatments (other than the current treatment) that are
          // not marked as deleted and is validated, then consider this as a follow-up.
          followup_treatment: wound.treatments
            ? wound.treatments.filter(
              x => !x.deleted
                && x.is_validated
                && x.encounter_id !== this.encounterId).length > 0
            : false,
          is_healed: false, // Mark the wound as not healed if the user adds a treatment
          updated: true,
        }
        this.openDialog(wound.practice_type_id)
      }, 1)
    },
    editTreatment(wound) {
      // Wound treatment form is quite large and there can be a rendering delay, so show the loading modal
      // if (wound.practice_type_id === 1) this.$store.dispatch('loading', true)
      clearTimeout(this.timeoutId)
      this.timeoutId = setTimeout(() => {
        this.wound = {
          ...wound,

          // Not in the DB, for Vue only
          // If there are treatments (other than the current treatment) that are
          // not marked as deleted and is validated, then consider this as a follow-up.
          followup_treatment: wound.treatments
            ? wound.treatments.filter(
              x => !x.deleted
                && x.is_validated
                && x.encounter_id !== this.encounterId).length > 0
            : false,
        }

        this.openDialog(wound.practice_type_id)
      }, 1)
    },
    deleteWoundRequest(index) {
      this.$root.confirm({
        title: 'Delete Wound and Treatment Record?',
        subTitle: 'Warning: This operation cannot be undone!',
        body: 'Are you sure you wish to delete this wound and treatment record?',
        confirm: 'Delete',
        confirmIcon: this.icons.mdiDelete,
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.wounds.splice(index, 1)
          this.$emit('input', this.wounds)
          this.$emit('update:wound-treatment-updated', true)
          this.hasTreatmentUpdate()
        }
      })
    },
    scrollToWound(id) {
      this.$nextTick(() => {
        const element = this.$refs[`wound-${id}`]
        if (typeof element !== 'undefined' && element[0]) {
          element[0].scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.wound-treatments {
  min-height: 150px;
  // max-height: 600px;
  // overflow-y: scroll;
  > .row .v-card__actions {
    margin-bottom: 16px !important;
  }
  .wound {
    border: thin solid rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 0 !important;
    margin-bottom: 20px;
    .v-card__actions {
      padding: 4px;
      margin: 0 !important;
      border-bottom: thin solid rgba(0, 0, 0, 0.14);
      button {
        margin: 0;
      }
      .practice-type-title {
        font-size: 16px;
        font-weight: 600;
        color: var(--v-secondary-darken3) !important;
        line-height: 2;
        padding: 0;
      }
    }
    .wound-treatment-details {
      margin: 0 !important;
      padding: 16px 4px;
      background-color: #fff;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
      .row {
        margin-left: 0;
        margin-right: 0;
        label, > div {
          line-height: 22px;
        }
        label {
          padding: 5px 10px 3px 0;
          color: rgba(94, 86, 105, 0.68);
        }
        > div {
          font-size: 16px;
          padding: 4px 0;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.68);
          &.warning--text {
            color: var(--v-warning-darken1) !important;
          }
        }
      }
    }
  }
}
</style>
