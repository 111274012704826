<template>
  <NoteSection
    label="Medications"
  >
    <btn
      label="Tap here to select medications"
      :icon="icons.mdiPill"
      class="col-sm-12"
      @click="modalState = true"
    ></btn>

    <!-- medications modal -->
    <v-dialog
      v-model="modalState"
      max-width="1024"
      scrollable
      @click:outside="clickOutside()"
    >
      <v-card>
        <v-card-title
          ref="medicationsTitle"
          class="pb-0"
        >
          Medications
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="max-height: 800px;">
          <check-boxes
            v-model="medication_values"
            :items="medications"
            class="col-sm-12"
            columns="4"
            column-sort
            return-object
          ></check-boxes>
        </v-card-text>
        <v-divider v-if="isOtherMedicine"></v-divider>
        <v-card-text style="max-height: 150px;">
          <text-area
            v-if="isOtherMedicine"
            v-model="medications_other"
            label="Enter other medications..."
            class="col-sm-12"
            counter="3200"
            maxlength="3200"
            required
          ></text-area>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <!-- clear -->
          <btn
            label="Clear All"
            color="secondary"
            :icon="icons.mdiCheckboxMultipleBlankOutline"
            :disabled="isSigned"
            @click="clear"
          ></btn>

          <v-spacer></v-spacer>

          <!-- done -->
          <btn
            label="Done"
            :icon="icons.mdiCheck"
            :disabled="isOtherMedicine && !medications_other"
            @click="save"
          ></btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </NoteSection>
</template>

<script>
import { mdiCheck, mdiCheckboxMultipleBlankOutline, mdiPill } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
    medicationsOther: {
      type: String,
      default: null,
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalState: false,
      medication_values: [...this.value],
      medications_other: this.medicationsOther,
      icons: { mdiPill, mdiCheckboxMultipleBlankOutline, mdiCheck },
    }
  },
  computed: {
    ...mapGetters('encounters', ['medications']),
    isOtherMedicine() {
      return this.medication_values.some(x => x.title === 'Other')
    },
  },
  watch: {
    modalState() {
      this.$emit('input', this.medication_values)
      this.$emit('update:medications-other', this.medications_other)

      // Scroll to top of modal
      const modalTitle = this.$refs.medicationsTitle
      if (modalTitle) {
        modalTitle.scrollIntoView(true)
      }
    },
  },
  methods: {
    clear() {
      this.$root.confirm({
        title: 'Clear All Medications?',
        body: 'Are you sure you wish to clear all medications?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          this.medication_values = []
          this.medications_other = null
        }
      })
    },
    save() {
      this.modalState = false
    },
    clickOutside() {
      this.save()
    },
  },
}
</script>
