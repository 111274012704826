// This should be the same across all practice-types
const encounterCore = {
  id: null,
  is_validated: false,
  is_signed: false,
  is_synced: false,
  is_revised: false,
  is_quick_entry: null,
  is_quick_entry_validated: true,
  quick_entry_room: null,
  quick_entry_notes: null,
  quick_entry_notes_to_nurse: null,
  is_deferred_care: false,
  deferred_care_reason: null,
  visit_type: null,
  visit_location: null,
  signed_by_user_id: null,
  signed_time: null,
  addendums: [],
  encounter_attachments: [],
  communication_log_id: null,
  final_note: null,
}

// Specific to this practice type
const encounterInitial = {
  advance_care_plan: null,
  encounter_covid_assessment: {},
  height_feet: null,
  height_inches: null,
  weight_pounds: null,
  bmi: null,
  health_change: null,
  health_change_other: null,
  general_appearances: [],
  general_appearances_other: null,
  temperature: null,
  temperature_scale: null,
  heart_rate: null,
  bp_systolic: null,
  bp_diastolic: null,
  respiratory_rate: null,
  encounter_pulse_assessment: {},
  allergies_other: null,
  sensitivities_other: null,
  therapeutic_anticoagulant: null,
  medications: [],
  medications_other: null,
  recently_hospitalized: null,
  recently_hospitalized_related: null,
  recently_hospitalized_date: null,
  mental_status: null,
  mobility: null,
  mobility_other: null,
  continence: null,
  continence_other: null,
  body_areas: [
    { id: 1, title: 'Head & Face' },
    { id: 2, title: 'Scalp' },
    { id: 3, title: 'Neck' },
    { id: 4, title: 'Back' },
    { id: 9, title: 'Buttocks' },
    { id: 10, title: 'Sacrum' },
    { id: 11, title: 'Coccyx' },
    { id: 12, title: 'Left Ischium' },
    { id: 13, title: 'Right Ischium' },
    { id: 14, title: 'Left Trochanter' },
    { id: 15, title: 'Right Trochanter' },
    { id: 16, title: 'Left Upper Extr' },
    { id: 17, title: 'Right Upper Extr' },
    { id: 18, title: 'Left Lower Extr' },
    { id: 19, title: 'Right Lower Extr' },
    { id: 20, title: 'Left Foot' },
    { id: 21, title: 'Right Foot' },
  ],
  body_areas_notes: null,
  medical_histories: [], // Used with old UI for medical histories [{ id: null }, { id: null }]
  medical_histories_other: null,
  neuropathy: null,
  neuropathy_type: null,
  neuropathy_severity: null,
  risk_factors: [], // Used with old UI for risk factors [{ id: null }, { id: null }]
  risk_factors_other: null,
  social_history_contributory: null,
  social_histories: [],
  social_history_other: null,
  family_history_contributory: null,
  family_history: null,
  dfu_risk: null,
  dfu_risk_reason: null,
  footwear_evaluation: null,
  footwear_evaluation_reason: null,
  footwear_exam_reason: null,
  footwear_notable_issues: [],
  a1c_level: null,
  encounter_active_wound_infection: {},
  assessment_plan: {},
  labs: [],
  labs_other: null,
  labs_order_date: null,
  visit_reason: null,
  visit_reason_add_to_note: false,
  chief_complaint: null,
  present_illness_history: null,
  systems_review: null,
  final_note_text: null,
  communication_log: {},
}

export default {
  data() {
    return {
      encounter: {
        ...this.$lodash.cloneDeep(encounterCore),
        ...this.$lodash.cloneDeep(encounterInitial),
      },
      patient: {
        wounds: [],
      },

      // Below is specific to this practice type
      practiceType: 1,
      woundTreatmentUpdated: false,
      requireVitals: false,
      hasWoundTreatmentType: false,
    }
  },
  computed: {
    // 20241231 JMC: Commenting out as it's not used.
    // Used in stress test page
    // testEncounterWoundCare() {
    //   return {
    //     ...this.encounter,
    //     practice_type_id: 1,
    //   }
    // },
  },
  mounted() {
  },
  methods: {
  },
}
