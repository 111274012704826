<template>
  <v-dialog
    v-if="locationData"
    v-model="modalState"
    width="700"
    persistent
  >
    <v-card>
      <v-form v-model="formValid">
        <v-card-title
          ref="locationTitle"
          class="pb-0"
        >
          Location
        </v-card-title>

        <NoteSection
          label="Select options below to specify location"
        >
          <help-icon
            title="ICD-10 Requirements"
            body="<p>ICD-10 requires greater specificity of locations. Please be as detailed as possible.</p>"
          ></help-icon>

          <select-box
            v-model="locationData.location"
            :items="locations"
            label="Location"
            class="col-sm-6"
            :required="$custom.isEmpty(locationData.optional_modifier) ? true : false"
            @input="locationChanged"
          ></select-box>

          <select-box
            v-if="locationData.location === 1"
            v-model="locationData.section"
            :items="sectionOptions"
            label="Section"
            class="col-sm-6"
            multiple
            :required="$custom.isEmpty(locationData.optional_modifier) ? true : false"
          ></select-box>
          <select-box
            v-else-if="locationData.location === 3"
            v-model="locationData.quadrant"
            :items="quadrantOptions"
            label="Quadrant"
            class="col-sm-6"
            :required="$custom.isEmpty(locationData.optional_modifier) ? true : false"
          ></select-box>
          <select-box
            v-else
            v-model="locationData.left_right"
            :items="lrOptions"
            :disabled="!locationData.location || isLocationBilateral"
            label="L/R"
            class="col-sm-6"
            :required="isLeftRightRequired"
          ></select-box>

          <radio-buttons
            v-if="showDigits"
            v-model="locationData.digits"
            :items="digitOptions"
            :disabled="!locationData.location"
            label="Digits"
            color="error"
            class="col-sm-12"
            columns="6"
            hide-details
            clearable
            :required="$custom.isEmpty(locationData.optional_modifier) ? true : false"
          ></radio-buttons>

          <v-divider
            v-if="showDigits"
          ></v-divider>

          <radio-buttons
            v-if="locationData.location === 32"
            v-model="locationData.finger_toe"
            :items="ftOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            hide-details
            clearable
            required
          ></radio-buttons>
          <radio-buttons
            v-else
            v-model="locationData.dorsal_plantar"
            :items="dpOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            hide-details
            clearable
          ></radio-buttons>

          <v-divider></v-divider>

          <radio-buttons
            v-model="locationData.medial_lateral_bilateral"
            :items="!isLocationLeftRight ? mlbOptions : mlOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            hide-details
            clearable
            :required="isMlbRequired"
          ></radio-buttons>
          <v-divider></v-divider>
          <radio-buttons
            v-model="locationData.anterior_posterior"
            :items="apOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            hide-details
            clearable
          ></radio-buttons>
          <v-divider></v-divider>
          <radio-buttons
            v-model="locationData.proximal_distal"
            :items="pdOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            hide-details
            clearable
          ></radio-buttons>
          <v-divider></v-divider>
          <radio-buttons
            v-model="locationData.inferior_superior"
            :items="isOptions"
            :disabled="!locationData.location"
            class="col-sm-12"
            columns="3"
            clearable
          ></radio-buttons>

          <text-field
            v-model="locationData.optional_modifier"
            label="Optional / Modifier"
            class="col-sm-12"
            counter="450"
            maxlength="450"
          ></text-field>

          <text-area
            v-model="locationData.location_text"
            class="col-sm-12"
            readonly
          ></text-area>
        </NoteSection>

        <div class="note-section mx-2">
          <v-expand-transition>
            <div v-if="isLocationBilateral">
              <alert color="warning">
                Note!  "Bilateral" is considered too ambiguous for billing purposes.  Please consider revising the stated location or defining multiple wounds instead.
              </alert>
            </div>
          </v-expand-transition>

          <v-expand-transition>
            <div v-if="isLocationBoth">
              <alert color="warning">
                Note!  "Both" is considered too ambiguous for billing purposes.  Please consider revising the stated location or defining multiple wounds instead.
              </alert>
            </div>
          </v-expand-transition>
        </div>

        <v-card-actions>
          <!-- exit -->
          <btn
            label="Exit"
            color="error"
            :icon="icons.mdiClose"
            @click="exit"
          ></btn>

          <v-spacer></v-spacer>

          <!-- clear -->
          <btn
            label="Clear All"
            color="secondary"
            :icon="icons.mdiUndoVariant"
            @click="clear"
          ></btn>

          <v-spacer></v-spacer>

          <!-- save -->
          <btn
            label="Save"
            :icon="icons.mdiCheck"
            :disabled="!formValid"
            @click="save"
          ></btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  mdiCheck,
  mdiClose,
  mdiUndoVariant,
} from '@mdi/js'
import { mapGetters } from 'vuex'

const initialData = {
  wound_id: null,
  left_right: null,
  quadrant: null,
  section: null,
  inferior_superior: null,
  proximal_distal: null,
  anterior_posterior: null,
  medial_lateral_bilateral: null,
  dorsal_plantar: null,
  digits: null,
  finger_toe: null,
  location: null,
  optional_modifier: null,
  location_text: null,
}

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
    woundId: {
      type: String,
      default: null,
    },
    modalState: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formValid: false,
      optionalModifierText: null,
      locationData: {
        ...initialData,
        wound_id: this.woundId,
        ...this.$lodash.cloneDeep(this.value),
        section: this.$custom.notEmpty(this.value?.section) ? this.value?.section?.split(',') : null,
      },
      lrOptions: [
        { title: 'Left' },
        { title: 'Right' },
      ],
      dpOptions: [
        { title: 'Dorsal' },
        { title: 'Plantar' },
      ],
      mlbOptions: [
        { title: 'Medial' },
        { title: 'Lateral' },
        { title: 'Bilateral' },
      ],
      mlOptions: [
        { title: 'Medial' },
        { title: 'Lateral' },
      ],
      apOptions: [
        { title: 'Anterior' },
        { title: 'Posterior' },
      ],
      pdOptions: [
        { title: 'Proximal' },
        { title: 'Distal' },
      ],
      isOptions: [
        { title: 'Inferior' },
        { title: 'Superior' },
      ],
      sectionOptions: [
        { title: 'Upper Right Quadrant' },
        { title: 'Epigastric Region' },
        { title: 'Upper Left Quadrant' },
        { title: 'Lower Right Quadrant' },
        { title: 'Umbilical Region' },
        { title: 'Lower Left Quadrant' },
      ],
      quadrantOptions: [
        { title: 'Upper Right Quadrant' },
        { title: 'Upper Left Quadrant' },
        { title: 'Lower Right Quadrant' },
        { title: 'Lower Left Quadrant' },
      ],
      ftOptions: [
        { title: 'Finger' },
        { title: 'Toe' },
      ],
      digitOptions: [
        { title: '1st', value: 1 },
        { title: '2nd', value: 2 },
        { title: '3rd', value: 3 },
        { title: '4th', value: 4 },
        { title: '5th', value: 5 },
        { title: '6th', value: 6 },
      ],
      icons: {
        mdiCheck, mdiUndoVariant, mdiClose,
      },
    }
  },
  computed: {
    ...mapGetters('baseData', ['locations']),
    showDigits() {
      return this.locationData.location === 18
        || this.locationData.location === 32
        || this.locationData.location === 49
    },
    locationTextLC() {
      return this.$custom.toLowerCase(this.locationData.location_text)
    },
    isLocationBilateral() {
      return this.locationTextLC.includes('bilateral')
        || this.locationTextLC.includes('bi-lateral')
    },
    isLocationLeftRight() {
      return this.locationTextLC.includes('left')
        || this.locationTextLC.includes('right')
    },
    isLocationBoth() {
      return this.locationTextLC === 'both'
        || this.locationTextLC.includes(' both')
        || this.locationTextLC.includes('both ')
    },
    isMlbRequired() {
      return this.locationData.location === 9
        || this.locationData.location === 21
        || this.locationData.location === 28
        || this.locationData.location === 33
        || this.locationData.location === 39
    },
    isLocationLateral() {
      return this.locationTextLC.includes('lateral')
    },
    isLeftRightRequired() {
      return this.locationData.location === 2
        || this.locationData.location === 4
        || this.locationData.location === 5
        || this.locationData.location === 6
        || this.locationData.location === 8
        || this.locationData.location === 12
        || this.locationData.location === 13
        || this.locationData.location === 14
        || this.locationData.location === 15
        || this.locationData.location === 16
        || this.locationData.location === 17
        || this.locationData.location === 18
        || this.locationData.location === 19
        || this.locationData.location === 20
        || this.locationData.location === 22
        || this.locationData.location === 23
        || this.locationData.location === 24
        || this.locationData.location === 26
        || this.locationData.location === 27
        || this.locationData.location === 30
        || this.locationData.location === 31
        || this.locationData.location === 32
        || this.locationData.location === 36
        || this.locationData.location === 40
        || this.locationData.location === 42
        || this.locationData.location === 43
        || this.locationData.location === 44
        || this.locationData.location === 45
        || this.locationData.location === 47
        || this.locationData.location === 48
        || this.locationData.location === 49
        || this.locationData.location === 51
        || this.locationData.location === 52
        || this.locationData.location === 53
        || (this.isMlbRequired && this.isLocationLateral)
    },
  },
  watch: {
    modalState() {
      // Scroll to top of modal
      const modalTitle = this.$refs.locationTitle
      if (modalTitle) {
        modalTitle.scrollIntoView(true)
      }
    },
    value() {
      this.locationData = {
        ...initialData,
        wound_id: this.woundId,
        ...this.$lodash.cloneDeep(this.value),
        section: this.$custom.notEmpty(this.value?.section) ? this.value?.section?.split(',') : null,
      }
    },
    locationData: {
      deep: true,
      handler() {
        this.updateLocationText()
      },
    },
  },
  mounted() {
  },
  methods: {
    locationChanged() {
      this.locationData = {
        ...initialData,
        wound_id: this.woundId,
        location: this.locationData.location,
      }
    },
    updateLocationText() {
      let locationText = ''
      Object.entries(this.locationData).forEach(i => {
        if (i[1] && i[0] !== 'location_text' && i[0] !== 'wound_id' && i[0] !== 'modified') {
          if (i[0] === 'location') {
            locationText += `${this.$store.getters['baseData/locationFromId'](i[1])} `
          } else if (i[0] === 'digits') {
            locationText += `${this.$custom.toOrdinal(i[1])} `
          } else if (i[0] === 'section' && this.$custom.notEmpty(i[1])) {
            locationText += this.$custom.toListCommaAnd(i[1], null, 'lc')
          } else if (i[0] === 'optional_modifier') {
            this.optionalModifierText = i[1]
            locationText += i[1]
          } else {
            locationText += `${i[1]} `
          }
        }
      })
      const properLocationTxt = this.$custom.toProperCase(locationText); // Assuming locationText is in proper case

      if (this.optionalModifierText) {
        // Create a regex to match only the exact word, not part of another word
        const regex = new RegExp(`\\b${this.$custom.toLowerCase(this.optionalModifierText.trim())}\\b`, 'i')

        // Perform the exact word replacement
        this.locationData.location_text = properLocationTxt.replace(regex, this.optionalModifierText)
      } else {
        this.locationData.location_text = properLocationTxt
      }
    },
    exit() {
      this.$root.confirm({
        title: 'Discard Location Changes and Exit?',
        body: 'Are you sure you wish to discard changes and exit?',
        cancel: 'Cancel',
        confirm: 'Discard / Exit',
        confirmColor: 'error',
      }).then(result => {
        if (result) {
          this.$emit('update:modal-state', false)
        }
      })
    },
    clear() {
      this.$root.confirm({
        title: 'Clear All Location Options?',
        body: 'Are you sure you wish to clear all location options?',
        cancel: 'No',
        confirm: 'Yes',
      }).then(result => {
        if (result) {
          this.locationData = {
            ...initialData,
            wound_id: this.woundId,
          }
        }
      })
    },
    save() {
      if (this.locationData.section) {
        this.locationData.section = this.locationData.section.join()
      }
      this.locationData.modified = this.$custom.utcNow()
      this.$emit('input', this.locationData)
      this.$emit('update:modal-state', false)
    },
  },
}
</script>
